import { ApiHandler } from './api-handler';
import { CwElementHandler } from './cw-element-handler';
import { Settings } from './types/settings.type';
import { Target } from './types/target.type';

const target: Target = {
  settings: 'OnlimChatbot',
  api: 'Onlim',
};

const w: any = window;

const settings: Settings = w[target.settings];
settings.widgetName = 'chatwidget';

const htmlContent = require('../../../dist/chat-app/app/index.html') as string;

const cssContent = require('../../../dist/chat-app/app/host.css');

const cwElementHandler = new CwElementHandler(
  window,
  htmlContent,
  cssContent,
  target,
  settings,
);

const apiHandler = new ApiHandler(cwElementHandler, w[target.api]);

w[target.api] = apiHandler.api;
// Make it backwards compatible
settings.boot = cwElementHandler.boot;
settings.shutdown = cwElementHandler.shutdown;
settings.open = () => w[target.api]('open');
settings.close = () => w[target.api]('open');

// auto booter
if (settings.autoBoot !== false && !apiHandler.bootIsQueued) {
  cwElementHandler.boot();
}
